
import MainContainer from "@/components/layout/mainContainer/MainContainer.vue";
import IconArrowBigBottom from "../../components/icons/IconArrowBigBottom.vue";
import IconArrowRight from "../../components/icons/IconArrowRight.vue";

import { computed, defineComponent, reactive, ref } from "vue";
import { useSettingsStore } from "../../store/settings";
import { storeToRefs } from "pinia";
import ConfirmDelete from "./modals/ConfirmDeleteModal.vue";
import { useAuthStore } from "@/store/auth";

export default defineComponent({
    name: "settings",
    components: {
        MainContainer,
        IconArrowBigBottom,
        ConfirmDelete,
        IconArrowRight,
    },
    setup() {
        const state = reactive({});
        const openMenu = ref<boolean>(true);
        const { user } = storeToRefs(useAuthStore());
        const menuItems = computed(() => {
            return [
                {
                    name: "Allgemeines",
                    route: "Settings",
                    requiredPermission: null,
                },
                {
                    name: "Profil & Sicherheit",
                    route: "SettingsProfile",
                    requiredPermission: null,
                },
                {
                    name: "Benutzerverwaltung",
                    route: "SettingsUserAdmin",
                    requiredPermission: "manage users",
                },
                {
                    name: "Rollenverwaltung",
                    route: "SettingsRoleAdmin",
                    requiredPermission: "manage roles",
                },
                {
                    name: "Stammdaten",
                    route: "SettingsBaseData",
                    requiredPermission: "manage norm base data",
                    subPages: [
                        {
                            name: "Richtlinien",
                            route: "SettingsBaseData",
                        },
                        {
                            name: "Arten",
                            route: "SettingsBaseDataTypes",
                        },
                        // {
                        //   name: "Status",
                        //   route: "SettingsBaseDataStates",
                        // },
                    ],
                },
                {
                    name: "E-Mail Normenaktualisierung",
                    route: "SettingsEmail",
                    requiredPermission: "manage norm email contents",
                    subPages: [
                        {
                            name: "Inhalt",
                            route: "SettingsEmail",
                        },
                        {
                            name: "Änderungsarten",
                            route: "SettingsEmailTypes",
                        },
                    ],
                },
            ];
        });

        function hasPermission(permission: string) {
            return user.value?.permissions?.find(
                (perm: string) => perm == permission
            );
        }
        const { chosenMenuItem, chosenSubPage } = storeToRefs(useSettingsStore());
        return {
            openMenu,
            state,
            menuItems,
            chosenMenuItem,
            chosenSubPage,
            hasPermission,
        };
    },
});
